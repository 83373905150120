import {
  type TestContext,
  addMethod,
  boolean,
  date,
  object,
  ref,
  string,
  type ArraySchema,
  type StringSchema,
  type DateSchema,
  type AnyObject,
  array,
} from "yup"

import { isTimeAfterTime } from "./date"
import { isValidBic, isValidIban } from "./misc"

import { type Commune, type AccessLevel } from "@/graphql/types"
import { i18n } from "@/i18n"
import { useSessionStore } from "@/store/session"
import { type AdditionalAddressForm } from "@/types"

// Matches only letters (A-Z, a-z), spaces, periods, and a range of Unicode characters for German accented letters (like Ä, Ö, Ü, and ß). Does not allow numbers.
export const alphaSpacePeriodRegex = /^[A-Za-z .\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u1E9E]*$/

// Matches only letters (A-Z, a-z), spaces, periods, and a range of Unicode characters for German accented letters (like Ä, Ö, Ü, and ß). It does allow numbers.
export const alphaNumericSpacePeriodRegex =
  /^[A-Za-z0-9 .\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u1E9E]*$/

export const installYupMethods = () => {
  addMethod<StringSchema>(string, "phone", function (this: StringSchema) {
    return this.matches(
      /^(?:(?:\+\(?(\d{1,3})\)?[\s-]?)?(?:\(?(\d{1,4})\)?[\s-]?)?(\d{1,4}[\s-]?){1,4})?$/,
      i18n.global.t("validation.phone_invalid")
    ).max(15, i18n.global.t("validation.phone_max_length"))
  })

  addMethod<StringSchema>(
    string,
    "matchesChars",
    function (this: StringSchema, regex: RegExp, allowedChars: string[]) {
      return this.matches(
        regex,
        i18n.global.t("validation.only_chars_allowed", { chars: allowedChars.join("") })
      )
    }
  )

  addMethod<ArraySchema<any[] | null | undefined, AnyObject>>(
    array,
    "unique",
    function (message, mapper = (a: any) => a) {
      return this.test("unique", message, (list) => {
        if (!list) return true

        list = list.map(mapper).filter((e) => e !== undefined)
        return list.length === new Set(list).size
      })
    }
  )

  addMethod<StringSchema>(string, "noNumbers", function (this: StringSchema) {
    return this.matches(/^[^0-9]*$/, i18n.global.t("validation.no_numbers_allowed"))
  })

  addMethod<StringSchema>(string, "noSpecialChars", function (this: StringSchema) {
    return this.matches(
      /^[a-zA-Z0-9 .\-&,\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u1E9E]*$/,
      i18n.global.t("validation.no_special_chars_allowed")
    )
  })

  addMethod<StringSchema>(string, "password", function (this: StringSchema) {
    return this.min(8, i18n.global.t("validation.password.length"))
      .matches(/[A-Z]/, i18n.global.t("validation.password.upper_case"))
      .matches(/[a-z]/, i18n.global.t("validation.password.lower_case"))
      .matches(/\d/, i18n.global.t("validation.password.digit"))
      .matches(/[!@#$%^&*(),.?":{}|<>]/, i18n.global.t("validation.password.special_char"))
  })
  addMethod<StringSchema>(string, "iban", function (this: StringSchema) {
    return this.test(
      "iban",
      i18n.global.t("validation.invalid_iban"),
      function (value: string | undefined) {
        return value === "" || value === undefined || isValidIban(value)
      }
    )
  })
  addMethod<StringSchema>(string, "zipCode", function (this: StringSchema) {
    return this.test(
      "is-min-zipcode",
      i18n.global.t("validation.zipCode_min", [4]),
      function (value: string | undefined) {
        const number = value && value.replace(/\D/g, "")
        return !(number && number.length < 4)
      }
    ).test(
      "is-max-zipcode",
      i18n.global.t("validation.zipCode_max", [5]),
      function (value: string | undefined) {
        const number = value && value.replace(/\D/g, "")
        return !(number && number.length > 5)
      }
    )
  })
  addMethod<StringSchema>(string, "bic", function (this: StringSchema) {
    return this.test(
      "bic",
      i18n.global.t("validation.invalid_bic"),
      function (value: string | undefined) {
        return !value || isValidBic(value)
      }
    )
  })
}

export const useInstitutionSchema = () => {
  const sessionStore = useSessionStore()
  return string().when([], {
    is: () => sessionStore.isUserOrganizationOrUnitOrHigher,
    then: (schema) => schema.required(i18n.global.t("validation.institution_required")),
  })
}

export const useSimpleAddressSchema = () =>
  object({
    street: string().trim().notRequired().max(30, i18n.global.t("validation.street_max", 30)),
    zipCode: string().trim().zipCode().notRequired(),
    district: string()
      .trim()
      .noNumbers()
      .notRequired()
      .max(30, i18n.global.t("validation.street_max", [30])),
    city: string()
      .trim()
      .noNumbers()
      .notRequired()
      .max(30, i18n.global.t("validation.street_max", [30])),
    country: string().trim().noNumbers().notRequired(),
  })

export const useContactSchema = () =>
  object({
    phone: string().phone().notRequired(),
    email: useEmailAddressSchema().notRequired(),
    phoneWork: string().phone().notRequired(),
    phoneMobile: string().phone().notRequired(),
    letterSalutation: string().trim().notRequired(),
    website: string()
      .trim()
      .notRequired()
      .nullable()
      .max(100, i18n.global.t("validation.max_character", [100])),
  })

export const useEmailAddressSchema = () =>
  string().trim().email(i18n.global.t("validation.email_invalid"))

export const usePasswordSchema = () => ({
  password: string().required(i18n.global.t("validation.password.required")).password(),
  confirmPassword: string().oneOf(
    [ref("password")],
    i18n.global.t("validation.password.not_matching")
  ),
})

const minUsernameLength = 3
export const useUserSchema = () => ({
  salutation: string().required(i18n.global.t("admin.benutzer.validation.salutation_required")),
  firstname: string()
    .trim()
    .required(i18n.global.t("admin.benutzer.validation.firstname_required")),
  lastname: string().trim().required(i18n.global.t("admin.benutzer.validation.lastname_required")),
  username: string()
    .trim()
    .required(i18n.global.t("admin.benutzer.validation.username_required"))
    .min(
      minUsernameLength,
      i18n.global.t("admin.benutzer.validation.username_min_length", { min: minUsernameLength })
    ),
  email: useEmailAddressSchema().required(
    i18n.global.t("admin.benutzer.validation.email_required")
  ),
  phone: string().phone().notRequired(),
})

export const useDateSchema = () => date().typeError(i18n.global.t("validation.invalid_date"))

export const usePersonSchema = (localePrefix = "validation") =>
  object({
    firstname: string()
      .trim()
      .required(i18n.global.t(`${localePrefix}.firstname_required`))
      .min(1, i18n.global.t(`${localePrefix}.firstname_min`, 1))
      .max(24, i18n.global.t(`${localePrefix}.firstname_max`, 24)),
    middlename: string().trim().notRequired(),
    lastname: string()
      .trim()
      .required(i18n.global.t(`${localePrefix}.lastname_required`))
      .min(1, i18n.global.t(`${localePrefix}.lastname_min`, 1))
      .max(24, i18n.global.t(`${localePrefix}.lastname_max`, 24)),
    gender: string()
      .trim()
      .required(i18n.global.t(`${localePrefix}.gender_required`)),
    salutation: string().trim().notRequired(),
    title: string().trim().notRequired(),
    denomination: string().trim().notRequired(),
    citizenships: array(string().trim()).notRequired(),
    languages: array(string().trim()).notRequired(),
    isGermanFirstLanguage: boolean().notRequired(),
  })

export const useBirthSchema = (dateRequired = false) => {
  let date: DateSchema<Date | null | undefined, AnyObject, undefined, ""> = useDateSchema().max(
    new Date(),
    i18n.global.t("validation.birthday_date_min")
  )
  if (dateRequired) date = date.required(i18n.global.t("validation.birthday_required"))
  else date = date.notRequired()

  return object({
    date,
    name: string().trim().notRequired(),
    city: string().trim().noNumbers().notRequired(),
    country: string().trim().notRequired(),
  })
}

export const useMovementSchema = (localePrefix = "validation") =>
  object({
    entry: useDateSchema().required(i18n.global.t(`${localePrefix}.entry_required`)),
    exit: useDateSchema()
      .notRequired()
      .test(
        "is-after-entry",
        i18n.global.t(`${localePrefix}.exitDate_after_entryDate`),
        (value, context) => !value || new Date(value) >= context.parent.entry
      ),
    reason: string().notRequired(),
  })

export type PasswordSchema = {
  password: string
  confirmPassword: string
}

export type BaseUserSchema = {
  firstname: string
  lastname: string
  username: string
  email: string
  salutation: string
} & PasswordSchema

export type UserAccessLevelSchema = {
  accessLevel: AccessLevel
  customerId?: string
  organizationId?: string
  organizationUnitId?: string
  institutionId?: string
  roleId: string
}

export const useInitialUserSchema = () => ({
  createInitialUser: boolean(),
  user: object().when("createInitialUser", ([createInitialUser], schema) =>
    createInitialUser
      ? schema.shape({
          ...useUserSchema(),
          ...usePasswordSchema(),
          ...{
            roleId: string().required(i18n.global.t("admin.benutzer.validation.role_required")),
          },
        })
      : schema
  ),
})

export type InitialUserSchema =
  | {
      createInitialUser: false
      user?: BaseUserSchema & {
        roleId: string
      }
    }
  | {
      createInitialUser: true
      user: BaseUserSchema & {
        roleId: string
      }
    }

export const useCommuneSchema = () => ({
  name: string().trim().required(i18n.global.t("stammdaten.gemeinden.validation.name_required")),
  communeKey: string()
    .trim()
    .required(i18n.global.t("stammdaten.gemeinden.validation.gemeindeschluessel_required")),
  contactPerson: object({
    name: string().trim().notRequired(),
    contact: useContactSchema().notRequired(),
  }),
  address: useSimpleAddressSchema(),
  additionalAddress: object({
    0: string(),
    1: string(),
  }),
  phone: string().phone().notRequired(),
  email: useEmailAddressSchema().notRequired(),
})

export const useInitialDomesticCommuneSchema = () => ({
  createInitialDomesticCommune: boolean(),
  commune: object().when(
    "createInitialDomesticCommune",
    ([createInitialDomesticCommune], schema) =>
      createInitialDomesticCommune
        ? schema.shape({
            ...useCommuneSchema(),
          })
        : schema
  ),
})

export type InitialDomesticCommuneSchema =
  | {
      createInitialDomesticCommune: false
      commune?: Omit<Commune, "additionalAddress"> & AdditionalAddressForm
    }
  | {
      createInitialDomesticCommune: true
      commune: Omit<Commune, "additionalAddress"> & AdditionalAddressForm
    }

export const requiredIfProvided = (fieldForValidation: string, message: string) => ({
  name: "isRequired",
  message,
  test: (value: string | undefined, context: TestContext): boolean => {
    const relatedField = context.parent[fieldForValidation]
    return !!value || !relatedField
  },
})

export const timeOrderTest = (
  fieldForValidation: string,
  message: string,
  dayName: string,
  isAfter = false
) => ({
  name: isAfter ? "isAfter" : "isBefore",
  message: i18n.global.t(message, { dayName: i18n.global.t(`weekdays.${dayName}`) }),
  test: (value: string | undefined, context: TestContext) => {
    return isAfter
      ? isTimeAfterTime(value, context.parent[fieldForValidation])
      : isTimeAfterTime(context.parent[fieldForValidation], value)
  },
})
